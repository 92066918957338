.item{
    width: 280px;
    background-color: white;
    text-align: center;
    
}

.item img{
    width:280px;
}

.item p{
    font-size: 20px;
    margin: 6px 6px;
}

.item-prices{
    display:flex;
    flex-direction: column;
    margin: 6px 6px;
}

.item-price-new{
    font-size: 20px;
    font-weight: 600;
    width:100%;
    text-align: center;
}

.item-stock{
    width:100%;
}

.item-stock-green{
    width:100%;
    color:green;
}

.item-price-old {
    color:#ce444c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover{
    transform: scale(1.05);
    transition: 0.6s;
    box-shadow: 0 5px 10px -5px #11385a;
}

