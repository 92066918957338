.collection{
    display: flex;
    flex-direction: column;
    align-items: center;
    /*gap:10px;*/
    /*margin-bottom: 50px;*/
}

.collection-title{
    text-align: center;
}

.collection h1{
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
}

.collection hr{
    width: 200px;
    height: 6px;
    border: 10px;
    background: #C5001A;
}

.collections{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 30px;
    gap:30px;
    
}

@media(max-width:1200px){
    .collection h1{
        font-size: 22px;
        font-weight: 500;
    }
    .collections{
        display:grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 30px;
        
 }
    
}


@media(max-width:800px){
    .collection h1{
        font-size: 18px;
        font-weight: 500;
    }
    .collections{
        display:grid;
        grid-template-columns: 1fr;
        margin-top: 30px;
        
 }
    
}