.faq-display-container {
    width:90%;
    max-width: 1500px;
    display: flex;
    margin:auto;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-top: 10px;
    background: #fff;
    box-shadow: 0 5px 10px -5px #11385a;
    padding-bottom: 50px;
}