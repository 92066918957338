.cartitems{
    width: 80%;
    background: white;
    margin: auto;
    margin-top: 10px;
    border:2px solid #C5001A;
    text-align: center;
    padding:10px;
}
.cartitems-format-main{
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 0.5fr ;
    align-items: center;
    padding:5px 0px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
}
.carticon-product-icon{
    height: 62px;
}

.cartitems-main-button {
    width: 50%;
    height: 20%;
    color: white !important;;
    background: #C5001A !important; 
    border: none;
    font-size: 10px;
    
}


.cartitems-total-item{
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
}

.cartitems-total{
    margin-top: 20px;
}


.cartitems-down{

    width:60%;
}
@media(max-width:800px){
    .cartitems{
        width: 90%;
    }
    .carticon-product-icon{
        height: 46px;
    }
    .cartitems-format-main{
        font-size: 12px;
    }
    .cartitems-down{

        width:98%;
    }
}
/*
.cartitems{
    margin: 50px 170px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 5px 10px -5px #11385a;
}

.cartitems hr{
    height: 3px;
    background: #002C54;
    border: 0px;
}



.cartitems-format{
    font-size: 17px;
    font-weight: 500;
    text-align: left;
}

.carticon-product-icon{
    height: 62px;
}

.cartitems-remove-icon{
    width:15px;
    margin: 0px 40px;
    cursor:pointer;
}

.cartitems-quantity{
    width: 64px;
    height: 50px;
    border: 2px solid #ebebeb;
    background: white;
}

.cartitems-down{
    display: flex;
    margin: 100px 0px;
}

.cartitems-total{
    flex:1;
    display: flex;
    flex-direction: column;
    margin-right: 200px;
    gap:40px;
}

.cartitems-total-item{
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
}

.cartitems-total button{
    width:262px;
    height:58px;
    outline: none;
    border: none;
    background: #C5001A;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.cartitems-total button:active{
    background: rgb(102, 0, 14);
}


.cartitems-promocode{
    flex:1;
    font-size: 16px;
    font-weight: 500;
}

.cartitems-promocode p{
    color: #555;
}

.cartitems-promobox{
    width:504px;
    margin-top: 15px;
    padding-left: 20px;
    height: 58px;
    background: #eaeaea;
}

.cartitems-promobox input{
    border:none;
    outline:none;
    background: transparent;
    font-size: 16px;
    width: 330px;
    height: 50px;

}

.cartitems-promobox button{
    width: 170px;
    height: 58px;
    font-size: 16px;
    background:black;
    color: white;
    cursor: pointer;
}

@media(max-width:800px){
    .cartitems{
        margin: 60px 50px;
    }
    .cartitems-format-main{
        grid-template-columns: 0.5fr 3fr 0.5fr 0.5fr 0.5fr 0.5fr;
        gap:10px;
        padding: 15px 0px;
        font-size: 13px;
    }

    .carticon-product-icon{
        height: 50px; 
    }
    .cartitems-remove-icon{
        margin: auto;
    }
    .cartitems-quantity{
        width: 40px;
        height: 30px;
    }

.cartitems-down{
    margin: 80px 0px;
    flex-direction: column;
    gap:80px;
}

.cartitems-total{
    margin: 0px;
}

.cartitems-total button{
    max-width: 200px;
    height: 45px;
    font-size: 13px;
}

.cartitems-promobox{
    width: auto;
    max-width: 500px;
}

.cartitems-promobox input{
    width: 100%;
}

.cartitems-promobox button{
    width: 120px;
    margin-left: -125px;
}

}
*/