.aboutdisplay-main {
    width:90%;
    max-width: 1500px;
    display: flex;
    margin:auto;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-top: 10px;
    background: #fff;
    box-shadow: 0 5px 10px -5px #11385a;
    
}

.aboutdisplay-title{
    text-align: center;
    margin-bottom: 10px;
}

.aboutdisplay-main hr{
    height: 6px;
    background: #11385a;
    margin-bottom: 10px;
}

.aboutdisplay-altmain hr{
    height: 6px;
    background: #C5001A;
}

.aboutdisplay-main h1{
    font-size: 28px;
}

.aboutdisplay-main h2{
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}

.aboutdisplay-right {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin:10px;
}

.aboutdisplay-mainright{
    margin-bottom: 20px;
}

.aboutdisplay-left {
    display: flex;

}

.ventilbt{
    margin-bottom: 10px;
}

.aboutdisplay-left img{
    margin-bottom: 10px;
}


.aboutdisplay-imgped{
    width:500px;
    margin-bottom: 10px;
    background: linear-gradient(180deg,#cbcfd3 0%,#fff 60%) ;
    background-image: linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.7)), url("/public/20241029_192810b.jpg");
    /*background: white;*/
    box-shadow: 0 5px 10px -5px #11385a;
}

@media(max-width:800px){
    .aboutdisplay-main {
        width:100%;
        font-size: 12px;
        text-align: center;
    }

    .aboutdisplay-left{
        display: none;
    }

    .aboutdisplay-main h1{
        font-size: 22px;
    }

    .aboutdisplay-main h2{
        font-size: 14px;
    }
    .aboutdisplay-imgped{
        width:300px;
    }
    

}