.processvalidation-main{
    width: 80%;
    background: white;
    margin: auto;
    margin-top: 10px;
    border:2px solid #C5001A;
    text-align: center;
    padding:10px;
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.processvalidation-main h1{
 margin-bottom: 20px;
}


.processvalidation-main h2{
    margin-bottom: 20px;
    font-size: 18px;
   }

   .processvalidation-main p{
    margin-bottom: 20px;
   }