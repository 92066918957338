*{
  margin:0;
}
body {
  margin: 0;
  background-color: #FDF6F6;
  color:#0b2946;
  font-family: "Poppins",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%; /* Empêche la modification automatique de la taille des polices */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html {
  font-size: 100%; /* Taille par défaut basée sur le navigateur */
  -webkit-text-size-adjust: 100%; /* Empêche le zoom automatique */
  touch-action: manipulation;
  -ms-touch-action: manipulation;
}
