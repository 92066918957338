.checkoutpayment-mainpp {
    width: 40%;
    max-width: 700px;
    min-width: 500px; /* Largeur minimale pour éviter l'écrasement */
    /*display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column; */
    margin: 0 auto;
    margin-top: 10px;
    box-shadow: 0 5px 10px -5px #11385a;
    background-color: white;
    padding: 12px;
    box-sizing: border-box;
}


.CheckoutPaymentPP {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    min-width: 500px; /* Largeur minimale pour éviter l'écrasement */
}

@media (max-width: 800px) {
    .checkoutpayment-mainpp {
        width: 96%;
        min-width: 320px;
        padding: 12px;
    }
}
