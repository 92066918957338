.loginsignup {
    width: 100%;
    height: 60vh;
    background-color: #FDF6F6;
    padding-top: 10px;
}

.loginsignup-container{
    width: 580px;
    height: 650px;
    background: white;
    margin: auto;
    padding: 20px 60px;
    border:2px solid #C5001A;
    text-align: center;
}
.loginsignup-container button{
    width: 50%;
    height: 50px;
    color: white;
    background: #C5001A;
    margin-top:40px;
    border: none;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 20px;
}
.loginsignup-container button:hover{

    background: #a50016;

}

.errorlog{
    color: #a50016;
}