.account-navbar {
    display: flex;
    flex-direction: column;

  }

.account-navbar-button {
  width:100%;
}


  /*
  .account-navbar-button {
    width: 220px;
    height: 50px;
    padding: 10px;
    margin: 5px !important;
    cursor: pointer;
    outline: none;
    border: none;
    background: #002C54 !important; 
    color: white !important; 
    font-size: 16px;
    font-weight: 600;
  }
  
  .account-navbar-button:hover {
    background: #001B3A !important; 
  }
  
  @media(max-width:800px){
    .account-navbar-button {
      width: 210px;
      height: 50px;
      padding: 10px;
      margin: 5px !important;
      cursor: pointer;
      outline: none;
      border: none;
      background: #002C54 !important; 
      color: white !important; 
      font-size: 12px;
      font-weight: 600;
    }
  }*/