


.nav-cart-count{
    width: 22px;
    height: 22px;
    display:flex;
    justify-content: center;

    align-items: center;
    /*
    margin-top: -35px;
    margin-left: -55px;*/
    border-radius: 11px;
    font-size: 14px;
    background-color: #C5001A;
    color: white;
}

.nav-login-cart{
    display: flex;
    align-items: center;
    padding: 10px;

}

.language-selector {
    margin-left: auto; /* Place the language selector to the left of the cart */
  }
  
  .language-selector button {
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .language-selector button:hover {
    text-decoration: underline;
  }