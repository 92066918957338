

.ratingbox{
    margin-top:10px;
    margin-bottom:10px;
}

.productdisplay {
    width:90%;
    max-width: 1500px;
    display: flex;
    margin:auto;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-top: 10px;
    background: #fff;
    box-shadow: 0 5px 10px -5px #11385a;
}

.productdisplay-main-img {
    height: 500px;
    margin-left: 10px;
}

.productdisplay-right {
    margin: 0px 20px;
}


.productdisplay-right h1 {
    font-size: 30px;
    font-weight: 500;
}


.productdisplay-right-prices {
    display: flex;
  
    flex-direction: row;
    gap:4px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 700;
}

.productdisplay-right-price-old {
    color: #818181;
    text-decoration: line-through;
}

.productdisplay-right-new {
    color: #CB0000;
    width:100px;
}
.productdisplay hr {
    border:none;
    width:100%;
    height:6px;
    border-radius: 10px;
    background-color: #C5001A;
}
.productdisplay-right-stock{
    display: flex;
    flex-direction: column;
    /*margin-bottom: 20px;
    font-size: 22px;*/
}

.productdisplay button{

    color: white;
    background: #002C54 !important;
    margin-bottom: 10px;

}
.productdisplay-right-category {
    margin-top: 10px;
    margin-bottom: 20px;

}

.productdisplay-right-category ul{
    margin-bottom: 20px;
    font-size:16px;
}

.productdisplay-right-category h2{
    font-size:22px;
}

.addtocartbox {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: var(--background-paper, white); /* Ajuste selon le thème ou utilise une couleur par défaut */
    border: 2px solid #000;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2); /* Correspond à la valeur "boxShadow: 24" */
    padding: 16px; /* Correspond à "p: 4" en Material-UI (4 x 4px = 16px) */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size:22px;

}


@media(max-width:1200px){
    .productdisplay {
        display: flex;
        width: 95%;
        flex-direction: column;

    }

    .productdisplay-right-prices {

        font-size: 24px;

    }
    
}


@media(max-width:800px){
    .productdisplay {
        display: flex;
        width: 95%;
        flex-direction: column;
        background-color: white;
        border:1px solid #d0d0d0;
    }

    .productdisplay-main-img {
        height: 280px;
        margin-left: 0px;
        

    }
    .productdisplay-img {
        text-align: center;
        margin-bottom: 10px;
    }
    .productdisplay-right-stock{
        font-size: 16px;
    }
    .productdisplay-right-prices {
        display: flex;
        flex-direction: row;
    }

    .productdisplay-right h1 {
        font-size: 24px;
    }

    .productdisplay-right-category h2{
        font-size:18px;
    }
    

    .productdisplay-right-prices {

        font-size: 18px;

    }

    .addtocartbox {
        font-size:18px;
    
    }
    .productdisplay-right-category ul{
        font-size: 14px;
    } 
    .productdisplay-right-new {
        color: #CB0000;
        width:80px;
    }

}