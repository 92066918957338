.accountaddress{
    display:flex;
    flex-direction: row;
}

.accountaddress-right{
    flex-grow: 1;
}
.accountaddress-main{
    width: 80%;
    /*height: 1000px;**/
    background: white;
    margin: auto;
    /*padding: 00px 60px;*/
    margin-top: 10px;
    border:2px solid #C5001A;
    text-align: center; 
}


.address-section-left{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.address-section-right{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.accountaddress-main-button {
    width: 50%;
    height: 20%;
    color: white !important;;
    background: #C5001A !important; 
    border: none;
    font-size: 10px;
    margin-top: 20px !important;

}

@media(max-width:800px){

    .accountaddress{
        flex-direction: column;

    }
    .accountaddress-main{
        width: 95%;

    }
    
    
        
}
