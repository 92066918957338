.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:50px;

    margin-top: 50px;
}

.footer a{
    color: #fff;
}


.footer-logo{
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer-logo p{
    font-size: 46px;
    font-weight: 700;
}

.footer-links{
    display: flex;
    list-style: none;
    gap: 50px;
    font-size: 20px;
}

.footer-links li{
    cursor: pointer;

}


.footer-copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 20px;
    text-align: center;
    /*background-color: red;*/
}

.footer-copyright2{

    align-items: center;
    font-size: 15px;
    width: 100%;
    background-color: #0b2946;
    color:#FDF6F6;
    padding-top: 30px;
    padding-bottom: 30px;
}

.footer-flex{
    display: flex;
    flex-direction: row;
    flex-basis: 50%;
}

.footer-copyright hr{
    width: 90%;
    border:none;
    border-radius: 10px;
    height:4px;
    background: #C5001A;
    margin-bottom: 10px;
}

.footer-language-selector{
    margin-top: 20px;
}

.footer-cpr{
    flex: 1; 
    margin-bottom: 20px;
}

.legal{
    /*width:100%;*/
    flex: 1; 
    text-align: center;
    /*margin-top: 10px;*/
    margin-bottom: 10px;
}
.seo{
    width:100%;
    text-align: center;
    color:#FDF6F6;
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 30px;
}


@media(max-width:800px) {
    .footer-logo{
        display: flex;
        align-items: center;
        gap: 10px;
        text-align: center;
    }
    
    .footer-logo p{
        font-size: 24px;
        font-weight: 700;
    }
    .footer-copyright2{
        font-size: 10px;
        flex-direction: column;
        /*padding: 20px;*/
    }

    .footer-copyright{
        font-size: 10px;
    }
    
    .footer-cpr{
        text-align: center;
    }
    .footer-flex{
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
    }
}