.descriptionbox{
    margin: 40px 170px;
    width:90%;
    max-width: 1500px;
    margin:auto;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-top: 10px;

}

.youtube-video{
    width:580px;
    height:315px;

}

.descriptionbox-navigator{
    display: flex;

}

.descriptionbox-logo{

    display: flex;
    gap:10px;
}

.descriptionbox-nav-box{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width:171px;
    height:70px;
    border:1px solid #d0d0d0;
    background-color: white;
}

.descriptionBox-carrouss{
    display: flex;
    gap:10px;
}
.descriptionbox-description{
    display: flex;
    flex-direction: column;
    gap:20px;
    border: 1px solid #d0d0d0;
    padding: 10px;
    padding-bottom: 70px;
    background-color: white;
    text-align: center;
    align-items: center;
    
}

.descriptionbox-description hr{
    width: 90%;
    border:none;
    border-radius: 10px;
    height:4px;
    background: #C5001A;
    margin-bottom: 10px;
}

.descriptionbox h1{
    font-size:28px;
}

.descriptionbox h2{
    font-size:24px;
}

.descriptionbox-imgpot{

    width:500px;
    margin-bottom: 10px;
    background: linear-gradient(180deg,#cbcfd3 0%,#fff 60%) ;
    background-image: linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.7)), url("/public/20241029_192810b.jpg");
    /*background: white;*/
    box-shadow: 0 5px 10px -5px #11385a;
}

.descriptionbox-imgled{

    width:400px;
}

.descriptionbox-imgped{

    width:600px;
}

.descriptionbox-imgped{
    margin-bottom: 10px;
    background: linear-gradient(180deg,#cbcfd3 0%,#fff 60%) ;
    background-image: linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.7)), url("/public/20241029_192810b.jpg");
    /*background: white;*/
    box-shadow: 0 5px 10px -5px #11385a;
}


@media(max-width:1200px){

    .descriptionbox h1{
        font-size:24px;
    }
    
    .descriptionbox h2{
        font-size:20px;
    }
    
}



@media(max-width:800px){
    .descriptionbox{
        width:95%;
    }
    .descriptionbox-imgpot{

        width:300px;
    }
    .descriptionbox-imgled{

        width:150px;
    }
    
.descriptionbox-imgped{

    width:300px;
}

.youtube-video{
    width:280px;
    height:158px;

}

.descriptionbox h1{
    font-size:22px;
}

.descriptionbox h2{
    font-size:14px;
}


.descriptionbox-description{
    padding: 5px;
    padding-bottom: 70px;
    gap:20px;
    
}

.descriptionbox-description p{
    font-size:12px;
    
}



}