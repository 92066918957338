.mentions-main{
    width: 80%;
    background: white;
    margin: auto;
    margin-top: 10px;
    border:2px solid #C5001A;
    text-align: center;
    padding:10px;
}

.mentions-main h2{
    margin-bottom: 10px;
    margin-top: 10px;
}

.mentions-main hr{
    border:none;
    width:100%;
    height:4px;
    border-radius: 10px;
    background-color: #C5001A;
    margin-top: 10px;
    margin-bottom: 10px;
}