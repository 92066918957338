.checkoutpayment-main{
    width: 80%;
    margin: auto;
    margin-top: 10px;
    /*border:2px solid #C5001A;*/
    box-shadow: 0 5px 10px -5px #11385a;
    text-align: center;
}

@media(max-width:800px){
    .checkoutpayment-main{
        width: 100%;
    }
}