
.accountdetails{
    display:flex;
    flex-direction: row;
}


.acountdetails-email{
    margin-bottom: 10px;
    font-size: 14px;
}

.accountdetails-right{
    
flex-grow: 1
}

.accountdetails-main{
    width: 80%;
    background: white;
    margin: auto;
    margin-top: 10px;
    border:2px solid #C5001A;
    text-align: center;
}


.accountdetails-email{
    margin-top: 10px;
    margin-bottom: 10px;
}

@media(max-width:800px){

    .accountdetails{
        flex-direction: column;

    }
    .accountdetails-main{
        width: 95%;

    }
    
    
        
}
.accountdetails-main-button {
    width: 50%;
    height: 20%;
    color: white !important;;
    background: #C5001A !important; 
    border: none;
    font-size: 18px;
    
}

.accountdetails-main-button:active {
    background: rgb(102, 0, 14) !important;
}

.accountdetails-main-button:hover {
    background: rgb(163, 0, 22) !important; 
}
/*
.accountdetails-input{
    width:300px;
}*/