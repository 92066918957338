.cgvdisplay-main{
    width: 80%;
    background: white;
    margin: auto;
    margin-top: 10px;
    border:2px solid #C5001A;
    text-align: center;
    padding:10px;
}

.cgv-listcountry{
    list-style-type: none;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cgvdisplay-main h3{
    margin-bottom: 10px;
    margin-top: 10px;
}
