.checkoutbox{
    width: 80%;
    background: white;
    margin: auto;
    margin-top: 10px;
    border:2px solid #C5001A;
    text-align: center;
    padding:10px;
}

.checkout-title{
    font-size: 20px;
    color:#002C54;
}
.checkoutbox-input button{
    width:262px;
    height:58px;
    outline: none;
    border: none;
    background: #C5001A;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
}
.checkoutbox-input button:active{
    background: rgb(102, 0, 14);
}

.checkoutbox-input button:hover {
    background: rgb(163, 0, 22) !important; 
}
 
.checkoutbox-input{
    display: flex;
    flex-direction: column;
    gap:10px;
    align-items: center;
    }
    .order-summary{
        margin-top: 10px;
    }
    .order-summary-items{
        width: 90%;
    }
    .order-summary-total{
        text-align: left;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 20px !important;
        color:#002C54;
        width: 80%;
    }
@media(max-width:800px){
    .checkoutbox{
        width: 90%;

    }
    .order-summary-total{
        font-size: 12px !important;
        width: 90%;
    }   

}

/*







*/