.accountorders {
    display:flex;
    flex-direction: row;
}

.accountorders h1{
    font-size: 30px;
}
        
.accountorders-main{
    width: 80%;
    background: white;
    margin: auto;
    margin-top: 10px;
    border:2px solid #C5001A;
    text-align: center;
    padding-bottom: 40px;
}
    

.accountorders-address{
    display:flex;
    justify-content: center;
    gap: 160px;
    color: #11385a;
}

.accountorders-products{
    text-align: center;
    margin-top: 20px;


    color: #11385a;
}

.accountorders-products hr{
    color:#C5001A;
    margin:10px;
}

.title{
    color:#C5001A;
}

.accountorders-table{
    width: 100%;
}

.accountorders-total{
    color: #11385a;
}

@media(max-width:800px){

    .accountorders{
        flex-direction: column;

    }
    .accountorders-main{
        width: 95%;

    }
    
    .accountorders-address{
        flex-direction: column;
        gap: 10px;
    }
    
        
}